import "core-js/modules/es.array.push.js";
// import selectComponent from '@/components/selectComponent';
import { getLabelList, getLawyerList } from '@/service/index.server';
import { getAllRepo, getHeadSpecialist, getSpecialist } from '../service/index.server';
export default {
  components: {},
  name: "talent",
  data() {
    return {
      dialogVisible: false,
      isShow: false,
      pageName: '',
      contentList: [],
      selectList1: [],
      selectList2: [{
        name: '律师',
        id: 1
      }, {
        name: '专家',
        id: 2
      }],
      page: 1,
      pageSize: 5,
      labelId: 0,
      type: 1,
      count: 0,
      repoData: [],
      talentData: [],
      queryForm: {
        page: 1,
        pageSize: 10,
        libraryTypeId: ''
      },
      repoId: 0,
      repoName: '',
      total: 0,
      talentInfo: {}
    };
  },
  created() {
    this.repoId = parseInt(this.$route.query.id);
    this.queryForm.libraryTypeId = parseInt(this.$route.query.id);
  },
  mounted() {
    this.pageName = this.$route.name;
    this.getLabelList();
    this.getLawyerList();
    console.log(this.repoId, this.repoId > 0);
    if (this.repoId) {
      this.getRepoData();
    }
    this.getTalentData();
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.talentInfo = {};
    },
    handleViewInfo(row) {
      this.talentInfo = row;
      this.dialogVisible = true;
      console.log(55555, this.dialogVisible);
    },
    formatSex(val) {
      const data = ['未知', '男', '女'];
      return data[val];
    },
    handlePage(val) {
      this.queryForm.page = val;
      this.getTalentData();
    },
    handleChange() {
      this.queryForm.page = 1;
      this.getTalentData();
      this.repoData.map(item => {
        if (item.id === this.queryForm.libraryTypeId) {
          this.repoName = item.name;
        }
      });
    },
    async getTalentData() {
      const res = this.repoId > 0 ? await getSpecialist(this.queryForm) : await getHeadSpecialist(this.queryForm);
      this.talentData = res.data;
      this.total = res.count;
    },
    async getRepoData() {
      const res = await getAllRepo();
      this.repoData = res.data;
      this.repoData.map(item => {
        if (item.id === this.repoId) {
          this.repoName = item.name;
        }
      });
    },
    pageChange(val) {
      this.page = val;
      this.getLawyerList();
    },
    selectResult(item, type) {
      if (type === '1') {
        this.labelId = item.id;
      } else if (type === '2') {
        this.type = item.id;
      }
      this.page = 1;
      this.getLawyerList();
    },
    toDetail(id) {
      this.$router.push({
        path: '/mainBox/talentDetail',
        query: {
          id
        }
      });
    },
    getLabelList() {
      getLabelList().then(res => {
        this.selectList1 = res.data;
      });
    },
    getLawyerList() {
      getLawyerList({
        page: this.page,
        pageSize: this.pageSize,
        labelId: this.labelId,
        type: this.type
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    yearFormat(val) {
      let d1 = new Date(val).getTime();
      let d2 = new Date().getTime();
      let time = Math.round((d2 - d1) / 1000 / 60 / 60 / 24 / 365);
      let string = `持证从业${time}年`;
      return time ? string : '';
    }
  }
};