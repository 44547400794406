import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mainbox"
};
const _hoisted_4 = {
  class: "head"
};
const _hoisted_5 = {
  class: "headsel"
};
const _hoisted_6 = {
  class: "list"
};
const _hoisted_7 = {
  class: "row"
};
const _hoisted_8 = {
  class: "name"
};
const _hoisted_9 = {
  class: "sex"
};
const _hoisted_10 = {
  class: "row"
};
const _hoisted_11 = {
  class: "row"
};
const _hoisted_12 = {
  class: "row"
};
const _hoisted_13 = {
  class: "btn"
};
const _hoisted_14 = {
  style: {
    "font-size": "24px",
    "font-weight": "bold"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createTextVNode("当前位置： ")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[4] || (_cache[4] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("div", _hoisted_4, [_cache[5] || (_cache[5] = _createElementVNode("div", {
    class: "headfont"
  }, "库别：", -1)), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_select, {
    modelValue: $data.queryForm.libraryTypeId,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.queryForm.libraryTypeId = $event),
    onChange: $options.handleChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.repoData, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.id,
        label: item.name,
        value: item.id
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"])])], 512), [[_vShow, $data.repoId > 0]]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.talentData, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: item.id
    }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1), _createElementVNode("span", _hoisted_9, _toDisplayString($options.formatSex(item.sex)), 1)]), _withDirectives(_createElementVNode("div", {
      class: "row"
    }, " 库别：" + _toDisplayString($data.repoName), 513), [[_vShow, $data.repoId > 0]]), _createElementVNode("div", _hoisted_10, " 单位：" + _toDisplayString(item.unit), 1), _createElementVNode("div", _hoisted_11, " 职务：" + _toDisplayString(item.duty), 1), _createElementVNode("div", _hoisted_12, [_cache[7] || (_cache[7] = _createElementVNode("div", {
      class: "pos"
    }, null, -1)), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_button, {
      round: "",
      style: {
        "background-color": "#EC4141",
        "color": "#FFFFF9",
        "width": "110px",
        "height": "40px"
      },
      onClick: $event => $options.handleViewInfo(item)
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("查看详情")])),
      _: 2
    }, 1032, ["onClick"])])])]);
  }), 128))])]), _createVNode(_component_el_row, {
    type: "flex",
    justify: "center",
    align: "middle",
    style: {
      "margin": "30px 0"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_pagination, {
      background: "",
      layout: "total,prev, pager, next",
      "prev-text": "上一页",
      "next-text": "下一页",
      total: $data.total,
      "page-size": $data.queryForm.pageSize,
      onCurrentChange: $options.handlePage
    }, null, 8, ["total", "page-size", "onCurrentChange"])]),
    _: 1
  }), _createVNode(_component_el_dialog, {
    title: "专家详情",
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.dialogVisible = $event),
    width: "30%",
    "before-close": $options.handleClose,
    center: "",
    style: {
      "padding": "20px 24px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      style: {
        "background-color": "#f7f7f7",
        "padding": "20px 30px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form, {
        "label-position": "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString($data.talentInfo.name), 1)]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_cache[8] || (_cache[8] = _createElementVNode("span", {
            class: "txt"
          }, "性别", -1)), _createTextVNode("：" + _toDisplayString($options.formatSex($data.talentInfo.sex)), 1)]),
          _: 1
        }), _withDirectives(_createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", {
            class: "txt"
          }, "库别", -1)), _createTextVNode("：" + _toDisplayString($data.repoName), 1)]),
          _: 1
        }, 512), [[_vShow, $data.repoId]]), _createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("span", {
            class: "txt"
          }, "单位", -1)), _createTextVNode("：" + _toDisplayString($data.talentInfo.unit), 1)]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("span", {
            class: "txt"
          }, "职务", -1)), _createTextVNode("：" + _toDisplayString($data.talentInfo.duty), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "before-close"])]);
}